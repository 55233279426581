import { useEffect, useRef } from 'react';

const Canvas = () => {
//	const canvasRef = useRef<HTMLCanvasElement | null>(null);
	const canvasRef = useRef(null);

	useEffect(() => {
		const canvas = canvasRef.current;
		if (!canvas) {
			return;
		}
		const context = canvas.getContext('2d');
	}, []);
	return <canvas ref={canvasRef} />;
}

export default Canvas;
