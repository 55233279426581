import './App.css';
import React, {Component} from 'react';
import DisplayBar from './components/DisplayBar';
import Nav from './components/Nav';
import CardBoard from './components/CardBoard2.js';
import SearchForm from './components/SearchForm.js';
import InfoBox from './components/InfoBox.js';
import Canvas from './components/Canvas.js';

class App extends Component {
    state = {
        url : 'https://db.ygoprodeck.com/api/v7/cardinfo.php?archetype=kaiju',
        searchList : [],
        deckList : [],
        displayCard : null,
        displayDeck: false,
        displaySearchbox: false,
        arrNum : null,
        showInfo : false
    }

    callbackSearchList = (childSearchList) => {
        this.setState({searchList : childSearchList})
    }
    callbackCardDisplay = (x) => {
        if(this.state.displayDeck){
            this.setState({displayCard : this.state.deckList[x], arrNum : x})
        }else{
        var card = this.state.searchList[x];
        this.setState({displayCard : card, arrNum : x})
        }
    }
    callbackAddCardToDeck = () => {
        if(this.state.displayDeck){
            const newDeck = this.state.deckList;
            newDeck.splice(this.state.arrNum, 1);
            this.setState({deckList : newDeck, arrNum : null, displayCard : null})
        }else{
        var card = this.state.searchList[this.state.arrNum];
        this.setState({deckList : this.state.deckList.concat(card)});
        console.log('arrNum : ' + this.state.arrNum);
    }
    }
    callbackDisplayDeck = () => {
        this.setState({displayDeck: true, displayCard : null})
    }
    callbackDisplaySearch = () => {
        this.setState({displayDeck: false, displayCard : null})
    }
    callbackSearchForm = () =>{
        this.setState({displaySearchbox : !this.state.displaySearchbox})
    }
    callbackChangeURL = (newURL) => {
        this.setState({ url : newURL, displayCard : null, displaySearchbox : false}, () => this.cardFetcher());
        console.log(this.state.url);
        //this.cardFetcher();
    }
    callbackDisplayInfo = () =>{
        this.setState({showInfo : !this.state.showInfo});
    }
    async cardFetcher(){
        const response = await fetch(this.state.url);
        const card = await response.json();
        if(card.error){
            this.setState({searchList : []});
        }else{
            this.setState({searchList : card.data});
            }
    }
    componentDidMount(){
        this.cardFetcher();
    }


  render() {
      return (
          <div>
              <div className='App'>
	      <Canvas />
              {console.log(this.state.displayCard)}
                  <div className='background disablescroll' >
                  {this.state.displaySearchbox ? <SearchForm  callbackSearchForm={this.callbackSearchForm} callbackChangeURL={this.callbackChangeURL}/> : null}
                  {this.state.showInfo ? <InfoBox callbackDisplayInfo={this.callbackDisplayInfo}/> : null}
                  <Nav callbackDisplayDeck={this.callbackDisplayDeck} callbackDisplaySearch={this.callbackDisplaySearch} displayDeck={this.state.displayDeck} callbackSearchForm={this.callbackSearchForm} callbackDisplayInfo={this.callbackDisplayInfo}/>
                        <CardBoard data={this.state.displayDeck ? this.state.deckList : this.state.searchList} callbackCardDisplay={this.callbackCardDisplay} displayDeck={this.state.displayDeck}/>
                      {this.state.displayCard ? <DisplayBar Card={this.state.displayCard} callbackAddCardToDeck={this.callbackAddCardToDeck} displayDeck={this.state.displayDeck} /> : <DisplayBar Card={this.state.displayCard}/>}
                  </div>
              </div>
          </div>
      );
  }
}

export default App;
//<CardFetcher parentCallback={this.callbackSearchList} url={this.state.url} callbackCardDisplay={this.callbackCardDisplay}/>
