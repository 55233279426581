import React from 'react';
import '../styles/SearchForm.css';
import {ReactComponent as X} from '../icons/X.svg';

function InfoBox(props){
    return(
        <div className='blurBox'>
            <div className='searchBox'>
                <X className='xbox' onClick={() => props.callbackDisplayInfo()} />
                <h1> Info </h1>
                <h5> Thank you for using CardViewer. CardViewer is currently in Beta and has many new features in development. </h5>
                <h5> The Upload and Download function for card lists are on hold pending legal study.</h5>
                <h5> The Showcase mode is still in development. Check back soon! </h5>
                <h2> How to use CardViewer </h2>
                <h5> Click the Search icon on the left Navbar.</h5>
                <h5> Search cards by Name, type, archetype, attack etc. </h5>
                <h5> Click cards and add them to your Deck </h5>
                <h5> Click the Deck icon on the left Navbar</h5>
                <h5> Enjoy your self-made deck! </h5>
                <h6>All card are property of Konami</h6>


            </div>
        </div>
    );
}
export default InfoBox;
