import React, {useState} from 'react';
import '../styles/Nav.css';
import { ReactComponent as Book } from '../icons/book.svg';
import { ReactComponent as Search } from '../icons/search.svg';
import { ReactComponent as Collection } from '../icons/collection.svg';
import { ReactComponent as Info } from '../icons/information-circle.svg';
import { ReactComponent as DoubleChev} from '../icons/doubleChev.svg';
import { ReactComponent as Upload} from '../icons/Upload.svg';
import { ReactComponent as Download} from '../icons/Download.svg';

function Nav(props){
    const [open, setOpen] = useState(false);
    return(
            <Navbar open={open}>
                <NavItem chev={'chev'}                icon={<DoubleChev />}  func={() => {setOpen(!open)}} />
                <NavItem text='Search'   icon={<Search />}      func={() => props.callbackSearchForm()}  />
                <NavItem text='Showcase' icon={<Book />}        func={() => {console.log('click')}}   />
                {props.displayDeck ?(
                 <NavItem text='Search Results'     icon={<Collection />}  func={() => {props.callbackDisplaySearch()}}/>)
                  :( <NavItem text='Deck'     icon={<Collection />}  func={() => {props.callbackDisplayDeck()}}/>)}
                 <NavItem text='Upload' icon={<Upload />} func={() => {console.log('upload')}} />
                 <NavItem text='Download' icon={<Download />} func={() => {console.log('download')}} />
                <NavItem text='Info'     icon={<Info />}        func={() => {props.callbackDisplayInfo()}}/>
            </Navbar>
    );
}
function Navbar(props){
    return(
            <nav className={props.open ? 'navbar navbar-open' : 'navbar navbar-closed'}>
                <ul className='navbar-nav'>{props.children}</ul>
            </nav>
    );
}
function NavItem(props){

    return (
            <li className='nav-item' onClick={props.func}>
                <p>{props.text}</p>
                <a className={props.chev ? 'icon-button chev' : 'icon-button'}>
                    {props.icon}
                </a>
                {props.open && props.children}
                </li>
            );
}

export default Nav;
