import React from 'react';
import '../styles/card.css';
import cardback from '../img/cardback.jpg';


const CardBoard = (props) => {

        if(props.data){

        }

        else if(props.data.length === 1){
            return(
                <div className='cardboard'>
                    <CardTemplate card={props.data} i={1} key={1} props={props} />
                </div>
            );
        }


    return(
        <div className='cardboard'>
            <div className='boardHead'>
                {props.displayDeck ? <h1>Deck</h1> : <h1>Search</h1>}
            </div>
            {props.data.map((card, i) => (
                <CardTemplate card={card} i={i} key={`card-${i}`} props={props} />
            ))}
        </div>
    );
}

function CardTemplate(card, i, props){
    return(
        <div className='maincontainer'>
            <div className='thecard' style={{animationDelay: + `${card.i*.2}` + `s`}}>
                <div className='thefront'>
                    <img src={'./dump/images/' + card.card.id +'.jpg'} alt={card.name} className='card' onClick={() => card.props.callbackCardDisplay(`${card.i}`)} />
                </div>
                <div className='theback'>
                    <img src={cardback} alt={'cardback'} className='card'/>
                </div>
            </div>
        </div>
    );
}
 export default CardBoard;
