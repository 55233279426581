import React from 'react';
import '../styles/DisplayBar.css'

export default class DisplayBar extends React.Component {
    state = {
        arrNum : '', //empty string to array might cause issues, check later
        name : '',
        atk : '',
        def : '',
        lvl : '',
        type : '',
        attr : '',
        desc : '',
        color : '',
        arch : '',
        picture : '',
        amazon : '',
        cardmarket : '',
        coolstuffinc : '',
        ebay : '',
        tcgplayer : ''


    }
    componentDidUpdate(prevProps) {
        if(prevProps.Card !== this.props.Card){
            this.updateDisplay();
        }
    }
    addCardToDeck = (x) => {
        console.log('adding card to deck callback');
        this.props.callbackAddCardToDeck(x);
    }
    updateDisplay = () => {
        if(this.props.Card == null){
            console.log("NULL CARD");
            this.setState({
                name    : '',
                atk     : '',
                def     : '',
                lvl     : '',
                type    : '',
                attr    : '',
                desc    : '',
                picture : '',
                arch    : '',
                amazon : '',
                cardmarket : '',
                coolstuffinc : '',
                ebay : '',
                tcgplayer : ''
            })
        }else{
        this.setState({
	    id	    : this.props.Card.id,
            name    : this.props.Card.name,
            atk     : this.props.Card.atk,
            def     : this.props.Card.def,
            lvl     : this.props.Card.level,
            type    : this.props.Card.race,
            attr    : this.props.Card.attribute,
            desc    : this.props.Card.desc,
            //picture : this.props.Card.card_images[0].image_url,
            arch    : this.props.Card.archetype,
            amazon  : this.props.Card.card_prices[0].amazon_price,
            cardmarket : this.props.Card.card_prices[0].cardmarket_price,
            coolstuffinc : this.props.Card.card_prices[0].coolstuffinc_price,
            ebay : this.props.Card.card_prices[0].ebay_price,
            tcgplayer : this.props.Card.card_prices[0].tcgplayer_price

        });
    }
    }

    render(){
        if(this.state.name === ''){
            return(
                    <div id='displaybar' className='displaybar'/>
            );
        }
        return(
            <div>
                {console.log(this.state.name)}
                <div  id='displaybar'       className='displaybar'>
                    <h2 id='namebar'        className='namebar' >{this.state.name}</h2>
                    <div className='displaycardshadow'>
                    <img id='displaycard'   className='displaycard' src={'./dump/images/' + this.state.id + '.jpg'} alt={this.state.name}/>
                    </div>
                    <div className='statbar'>
                    {this.state.atk >= 0 && <h2 id='atkbar'         className='atkbar'  >ATK:{this.state.atk}</h2>}
                    {this.state.def >= 0 && <h2 id='defbar'         className='defbar'  >DEF:{this.state.def}</h2>}
                    </div>
                    {this.state.lvl && <h3 id='lvlbar'         className='lvlbar'  >LVL:{this.state.lvl}</h3>}
                    <h3 id='typebar'        className='typebar' >{this.state.type}</h3>
                    <h3 id='attrbar'        className='attrbar' >{this.state.attr}</h3>
                    <h5 id='descbar'        className='descbar' >{this.state.desc}</h5>
                    <h6 id='archbar'        className='archbar' >{this.state.arch ? 'Archetype: ' + this.state.arch : null}</h6>

                    {this.props.displayDeck ? <button className='addDeckButton' onClick={() => this.props.callbackAddCardToDeck(`${this.state.arrNum}`)} >Remove Card from Deck</button> : (
                        <button className='addDeckButton' onClick={() => this.props.callbackAddCardToDeck(`${this.state.arrNum}`)} >Add Card to Deck</button>
                    )}
                    <div className='pricelist'>
                        <div className='priceblock' id='amazon'>
                            <h5>amazon</h5>
                            <h5>${this.state.amazon}</h5>
                        </div>
                        <div className='priceblock' id='cardmarket'>
                            <h5>cardmarket</h5>
                            <h5>${this.state.cardmarket}</h5>
                        </div>
                        <div className='priceblock' id='coolstuffinc'>
                            <h5>coolstuffinc</h5>
                            <h5>${this.state.coolstuffinc}</h5>
                        </div>
                        <div className='priceblock' id='ebay'>
                            <h5>ebay</h5>
                            <h5>${this.state.ebay}</h5>
                        </div>
                        <div className='priceblock' id='tcgplayer'>
                            <h5>tcgplayer</h5>
                            <h5>${this.state.tcgplayer}</h5>
                        </div>
                    </div>

                    <div className='tinydeck'>
                    <p>placeholder</p>
                    </div>
                </div>
            </div>
        )
    }
}
