import React from 'react';
import '../styles/SearchForm.css';
import {ReactComponent as X} from '../icons/X.svg';

export default class SearchForm extends React.Component{
    constructor(props){
        super(props)
        this.state = {
            name : '',
            type : '',
            race : '',
            attr : '',
            atk  : '',
            def  : '',
            lvl  : '',
            arch : '',
            sort : ''
        }
    }
    genURL = () => {
        console.log(this.state);
        console.log(typeof(this.state));
        console.log(Object.values(this.state));
        console.log(typeof(Object.values(this.state)));
        console.log(typeof(this.state.name));

        let concatList = Object.values(this.state).filter(e => typeof e === 'string' && e !== '' );
        console.log('concat 1st ', concatList);
        concatList = concatList.join('&');
        console.log('concat 2nd' ,concatList);

        const url = 'https://db.ygoprodeck.com/api/v7/cardinfo.php?' + concatList + '&num=255&offset=0';
        console.log(url);
        this.props.callbackChangeURL(url);
    }

    changeName = event => {
        this.setState({name : 'fname=' + event.target.value});
    }
    changeType = event => {
        this.setState({type : 'type=' + event.target.value});
    }
    changeRace = event => {
        this.setState({race : 'race=' + event.target.value});
    }
    changeAttr = event => {
        this.setState({attr : 'attribute=' + event.target.value});
    }
    changeATK = event => {
        this.setState({atk : 'atk=' + event.target.value});
    }
    changeDEF = event => {
        this.setState({def : 'def=' + event.target.value});
    }
    changeLVL = event => {
        this.setState({lvl : 'level=' + event.target.value});
    }
    changeArch = event => {
        this.setState({arch : 'archetype=' + event.target.value});
    }
    changeSort = event => {
        this.setState({sort : 'sort=' + event.target.value});
    }


    render(){
        return(
            <div className='blurBox' >
                <div className='searchBox'>
                    <X className='xbox' onClick={() => this.props.callbackSearchForm()}/>
                    <h1>SEARCH</h1>
                    <div>
                        <div>
                            <input placeholder='Name' className='largeInput' onChange={this.changeName}/>
                        </div>
                        <div>
                            <input placeholder='Archetype' className='largeInput' onChange={this.changeArch}/>
                        </div>
                        <div>
                            <input placeholder='LVL' className='smallInput' onChange={this.changeLVL}/>
                            <input placeholder='ATK' className='smallInput' onChange={this.changeATK}/>
                            <input placeholder='DEF' className='smallInput' onChange={this.changeDEF}/>
                        </div>
                        <div>
                            <select>
                                <option value='' disabled selected>Card Type:</option>
                                <option>Monster</option>
                                <option>Spell</option>
                                <option>Trap</option>
                            </select>
                            <select onChange={this.changeType}>
                                <option value='' disabled selected>Monster Type</option>
                                <option value='Effect Monster'>Effect</option>
                                <option value='Flip Effect Monster'>Flip</option>
                                <option value='Fusion Monster'>Fusion</option>
                                <option value='Gemini Monster'>Gemini</option>
                                <option value='Link Monster'>Link</option>
                                <option value='Normal Monster'>Normal</option>
                                <option value='Pendulum Effect Monster'>Pendulum</option>
                                <option value='Ritual Effect Monster'>Ritual</option>
                                <option value='Spirit Monster'>Spirit</option>
                                <option value='Synchro Effect Monster'>Synchro</option>
                                <option value='Toon Monster'>Toon</option>
                                <option value='Tuner Monster'>Tuner</option>
                                <option value='Union Effect Monster'>Union</option>
                                <option>XYZ</option>
                            </select>
                            <select onChange={this.changeRace}>
                                <option value='' disabled selected>Monster Class</option>
                                <option value="aqua">Aqua</option>
                                <option value="beast">Beast</option>
                                <option value="beast-warrior">Beast-Warrior</option>
                                <option value='Creator-God'>Creator-God</option>
                                <option value='cyberse'>Cyberse</option>
                                <option value='dinosaur'>Dinosaur</option>
                                <option value='divine-beast'>Divine-Beast</option>
                                <option value='dragon'>Dragon</option>
                                <option value='fairy'>Fairy</option>
                                <option value='fiend'>Fiend</option>
                                <option value='fish'>Fish</option>
                                <option value='insect'>Insect</option>
                                <option value='machine'>Machine</option>
                                <option value='plant'>Plant</option>
                                <option value='psychic'>Psychic</option>
                                <option value='pyro'>Pyro</option>
                                <option value='reptile'>Reptile</option>
                                <option value='rock'>Rock</option>
                                <option value='sea serpent'>Sea Serpent</option>
                                <option value='spellcaster'>Spellcaster</option>
                                <option value='thunder'>Thunder</option>
                                <option value='warrior'>Warrior</option>
                                <option value='winged beast'>Winged Beast</option>
                            </select>
                            <select onChange={this.changeAttr}>
                                <option value='' disabled selected>Attribute</option>
                                <option>Dark</option>
                                <option>Earth</option>
                                <option>Fire</option>
                                <option>Light</option>
                                <option>Water</option>
                                <option>Wind</option>
                            </select>
                            <select>
                                <option value='' disabled selected>Spell Type</option>
                                <option>Normal</option>
                                <option>Field</option>
                                <option>Equip</option>
                                <option>Continuous</option>
                                <option>Quick-Play</option>
                                <option>Ritual</option>
                            </select>
                            <select>
                                <option value='' disabled selected>Trap Type</option>
                                <option>Normal</option>
                                <option>Continuous</option>
                                <option>Counter</option>
                            </select>
                            <select onChange={this.changeSort}>
                                <option value='' selected disabled>Sort</option>
                                <option>Name</option>
                                <option>Atk</option>
                                <option>Def</option>
                                <option>Lvl</option>
                                <option>Type</option>
                                <option>New</option>
                            </select>
                            <div>
                                <button className='submitButton' onClick={() => this.genURL()}>Submit</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        );
    }
}
